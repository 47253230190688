.loader-counters {
    position: absolute ;
    /*top:calc( - 25% - 10px);*/
    top: 310px;
    /* top:calc(40%); */
    left: calc(46%);
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #015c8a;
    z-index: 10;
    
    width: 10px;
    height: 10px;
    

    animation: spin 1s linear infinite;
    -animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite; /* Safari */
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }