.firstrow{
   
    width:8em;
}
#value {
 
    width:8em;
}

#label{
    float:left;
}
#check{
    float:right;
}
.thenumber{
    width:8em;
}

.single-threshold{
    margin: 1em 0.5em 0.1em 0.5em;
    
}