#LVwrap {
    height:100rem;
    width:200rem;
    background-color: aquamarine;
}


#container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

    border: solid;
    border-width: thin;
    border-color:#007bff;
    border-radius: 0.5em;
    


  }
  
  /* Then style the iframe to fit in the container div with full height and width */
 #responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 98%;
    height: 98%;
    margin:1%;
  }