.rep-wrap{
    width: 100%;
}

.rep-content-wrap{

  
}

.growthRep{
    height: 40em;
    overflow-y:scroll;
    overflow-x: scroll;
}

.dateRangeWrap {
    background-color: blueviolet;
    z-index: 2000;
    width:fit-content;
    float:left;
}

.divtest{
    margin-top: 2em;
    background-color: bisque;
    height:8em;
    width: 100%;
}

.colSelectWrap{
    width:fit-content;
    padding: 1em;
    background-color:chartreuse;
}

.ColsInvisible{

    height: 0px;
    overflow: hidden;

    transition: height 3.0s ease-out;
    -moz-transition: height 3.0s ease;
    -webkit-transition: height 3.0s ease;
    -o-transition: height 3.0s ease;
    transition: height 3.0s ease;
    

}
.Cols{
    position: relative;

    width:100%;
    padding:1em;
    margin-bottom: 1em;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
    
    
}

.colselwrap{
    width:fit-content;
    background-color: blueviolet;
}


.olClass{
    position:absolute;
    background-color: white;
   
    padding: 2px 10px;
    
    border-radius: 5px;
    border:solid;
    border-color: gray;
    border-width: thin;
    
    
  }

  /*
  .colSelect{
    float:right;
    
  }
*/
  .menuWrap{
    display: flex;
    width:100%;
    justify-content:space-evenly;
    margin-top:0.5em;
    
  }


