.view_images_header_div{
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-bottom: 15px;
    padding: 1px 0px 30px 30px;
}
.view_images_header_div > span {
    font-size: 18px;
    font-family: Roboto!important;
}
.submit_button{
    position: relative;
    padding: 8px 8px 8px;
    top: 4px;
    margin-left: 5px;
    font-size: 18px;
    /* height: 48px; */
    width: 95px;
    border-width: 0;
    outline: none;
    border-radius: 2px;
    background-color: #047dc4;
    color: #ecf0f1;
    transition: background-color .3s;
    border: 0.5px solid gainsboro;
}
.submit_button:hover, .submit_button:focus {
    background-color: #004e75;
}
.date_span{
    margin-left:15px;
}
.FarmPopSelect select {
    position: relative;
    top: 4px;
    /* height: 48px; */
    /*height: 34px;*/
    width:175px;
    border: 0.5px solid gainsboro;
    font: Arial;
    color: black;
    font-size: 18px;
    margin-right: 10px;
    border-radius: 2px;
    padding: 8px 8px 8px;
}
.DateInput_input{
    padding: 7px 11px 7px!important;
}