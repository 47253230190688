.sq1{
    width: 400px;
    height: 200px;
    background-color: red;
}

.sq2{
    width: 300px;
    height: 200px;
    background-color: blue;
}

.buttonSubText {
    font-size: xx-small !important;



}




.addfeed-box{
   
    position: relative;
    display:inline-block;
    float: left;
    margin: 0.4em;
    border: solid;
    border-radius: 0.3em;
    border-width: thin;
    border-color: gray;



    

}

.addfeed-content{
    padding:2em 2em 4em 2em;
}
.addfeed-container{
   

    display: flex;
    flex-wrap: wrap;

    width: 100%;


}

.addfeed-box-footer{
    position: absolute;
        bottom:0px;
        width: 100%;
        padding: 0.2em;
    
        
    }
.addfeed-typeselect{
    width: 12em;
}




.ped-modified{
    color: blue;

}

.ped-popnamelist{
    margin:0.5em 0.2em 1em 0.2em;
    position: relative;
    z-index: 99;
}

.ped-new-pop{
    color: blue;
    text-align: center;
    width: 100%;
}

.ped-biomass{
    width: 100%;
    text-align: center;
    padding: 0.3em 0 0.3em ;
}

/*PopEdit*/
.ped-container{
   
        /*background-color: darkorange;*/
       
        display: flex;
        flex-wrap: wrap;
      
  
        width: 100%;
        /*
              min-height: 200px;
        display: block;
*/
}



.ped-box{

    position: relative;
    float: left;
    width: 14em;
   /* height: 24em;*/

    margin: 0.2em 0.2em 1em 0.2em;

    border: solid;
    border-radius: 0.3em;
    border-width: thin;
    border-color: gray;




  
}


#InpCorrFactor  {
    float: right;
    width:6em;
}
#InpFCR  {
    float: right;
    width:6em;

}

#InpCorrFactor  {
    float: right;
    width:6em;
}

#InpTemp  {
    float: right;
    width:6em;
}

#InpPedSilos {
    background-color: greenyellow;
 color:green;

}



/*
.ped-box > form-control {
    width: 100px   !important;
}
*/

.ped-box-title{
 
  /*  width: 100%;*/
margin:2px;
    text-align: center;
    font-weight: bold;

    text-align: center;
    background-color:lightgray;

    border-radius: 3px 3px 0px 0px;
}

.ped-box-content{
    padding:0.2em 0.2em 0.2em 0.1em;
   
 
}

.ped-box-editnums{
    padding-bottom: 7em;

}


.ped-box-population{
    padding-bottom: 3.5em;
}

.ped-box-population-name{
    width: 100%;
    text-align: center;
    font-size: larger;
 
}

.ped-CBox-wrap{
    margin:5px;
}

.ped-button-wrap{
    width: 100%;
    padding: 0.2em;

}

.ped-box-footer{
position: absolute;
    bottom:0px;
    width: 100%;
    padding: 0.2em;

    
}

.ped-numfish-table-wrap{
 
    padding-top: 1em;
/*
    background-color: lawngreen;
   */
}
.ped-numfish-table-wrap td{
    padding: 0 0.5em 0 0.5em;
 
    border: solid;
    border-width: thin;
    border-color: silver;
}
.ped-numfish-table-wrap table {
   
   /* background-color:violet;*/
 
    width: 100%;
}





.feed_hidden {
    display: none;
}

.feed-container{
    
    position: relative;
    /*height: 100vh;*/
    padding-bottom: 15px;
    padding-top: 15px;
 

}


.feed-ErrorMSG{
    font-style: italic;
    font-size: large;
    font-weight: lighter; 


}

.feed-farm-select{
    
    width: 14em;
    z-index: 10;
  
    display:inline-block;
    margin-left: 1em;
    margin-right: 1em;
    
}


.feed-title {
    font-size: 2.5vh;
    float: left;
    width: 6em;

}


.table-hover tbody tr:hover td {
  
    /*font-weight: bolder;*/

    font-weight: lighter;
 
    
}



.tv-container__cages{
    width: 50%;
    min-width: 40em;
    /*max-height: 60em;
    overflow-y: auto;*/
  
}

.cv-container_cages {
    width: 70%;
    min-width: 40em;
}


.tv-container__right{
    margin: 0 0 0 0.5%;
  /*  width: 49%;*/
/*min-width: 30em;*/
  
    
 
 
    /*min-height: 600px;*/
}




.tv-silo-edit{
    background-color: rgb(0, 102, 255);
    min-height: 200px;
    width: 100%;
    display: block;
}

.tv-silos{
 
  display: block;
  
   width: 100%;
   
   min-width: 20em;
    display: flex;
  
}




.box-shadow{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.feedview-selecting{
    padding: 5px 0 0 10px;
    margin: 10px;
    width: 180px;
    height: 40px;
    cursor: pointer;
  
}

.flex-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

}

.feedview-selecting:hover {
    cursor: pointer;
    transform: scale(1.1);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.gray-text{
    color:rgb(119, 54, 54);
}

.pointer{
    cursor: pointer;
}

.select-react-component{
    width: 200px;
}

.delete-icon{
    font-size: 20px;
    color: red;
}

.td-delete-icon{
    width: 30px;
}

#left-tabs-pane-4 > div > div.table-container > div.react-bootstrap-table > table > tbody > tr:nth-child(1) > td:nth-child(4){
    width: 80px;
}

#left-tabs-pane-4 > div > div.table-container > div.react-bootstrap-table > table > tbody > tr:nth-child(1) > td:nth-child(4) > i:hover{
    transform: scale(1.1);
    color: rgb(179, 20, 20);
    cursor: pointer;
}

.td-delete-icon > i:hover{
    transform: scale(1.1);
    color: rgb(179, 20, 20);
}

.td-delete-icon{
    width: 30px;
}

.td-delete-icon > i:hover{
    transform: scale(1.1);
    color: rgb(179, 20, 20);
}
.delete-icon-list{
    cursor: pointer;
    font-size: 18px;
    list-style: none;
}

.delete-icon-list i{
    font-size: 16px;
    color: red;
}

.delete-icon-list > i:hover{
    transform: scale(1.1);
    color: rgb(179, 20, 20);
}

.role-component{
    padding-top:25px;
}

#user-role{
    height:38px;
}

#left-tabs > div > div {
    min-height: 1000px;
    height: 100%;
}
.not-allowed {
    cursor: not-allowed;
}

.not-allowed:hover {
    cursor: not-allowed;
}

.table-container > div:nth-child(2) > div > div > table > tbody > tr > td:nth-child(4) > i:hover {
    transform: scale(1.1);
    
}
.feed-veiw-table div > table > tbody > tr{
    cursor: pointer;
}

.feed-veiw-table div > table > thead > tr> th{
    cursor: pointer;
}


.feed-status-strip-wrap{
    
    position: relative;
    display: flex;
    margin-bottom: 1em;
    

    
}

.slow-spin {
    -webkit-animation: fa-spin 12s infinite linear;
    animation: fa-spin 12s infinite linear;
  }



.feed-farmselect-wrap {
    
    float:left;
    
    width: 100%;
    margin-bottom: 1em;

}
.status-wrap {

}

.status-single{
    display: inline-block;
    padding: 0.3em; 
    float:left;
    margin:0em  0.5em 0.5em 0.5em;
   /* width: 20em;*/
   color:#818181;
    
    border:solid;
    border-width: thin;
    border-color: silver;
}

.color-red{
    color:red;
}

.float-left{
    float: left;
}

.stat-title-and-select{

}

.status-alarm-text{

   
    


}

.status-alarm-entry{
    display: inline-flex;
    float:left;
 
}

.status-sysidx{


    font-size: 1.7em;
 
    /*font-family:'Times New Roman', Times, serif;*/
    padding-right:0.3em ;


}




.smallText{
    font-size: xx-small;
    text-align: center;
}
   
.colorGray{
    color: gray;
}

  .profpreview {
    width: 70px;
    height: 21px;

/*
    border: 1px solid #ccc;
    border-radius: 4px;
  */  
  }


  .popover{
      background-color:rgba(255,255,255,0.8);
      
  }

