.inl {
    display: block;
    font-size: 1.2rem!important;  
}
.inl-front {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: black;
}
.inl-back {
    display: inline-block;
    width: auto;
    text-align: left;
    color: #686868;
}


.table-wrap{
    width: 100%;


}

#mse-wrap {

    /*border:solid;*/

    padding: 10px;
  
    text-align: center;

}

.spinner-wrap{
    margin-left: 45%;

}

.unit-wrap{
   /* background-color: aquamarine;*/
    border:solid;
    border-width: 1px;
    border-color: #ddd;
    border-radius: 1em;
    margin-top:2em;
    padding:1em;

}

.siz-h{
    font-size:110%;
}

.siz-m{
    font-size: 100%;
}

.siz-s{
    font-size: 90%;
}

.nowrap{
    /*white-space:;*/
}