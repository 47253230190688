.wrap{

width:100%;
height: 800px;



}

#XXXXmyCanvas {
    width: 2000px;
    height: 1200px;
    border:solid;
    padding: 10px;

}
.canvas-wrap{
  /*  background-color: yellow;*/
    height:75%;
   /* max-height: 750px;*/
    width:100%;
    border:solid;
    border-width: 1px;
    border-color:silver;
    padding:-10px;

}
#sliderDiv {
    width:100%;
    height:10px;
    
}


#SliderDiv{
    width: 98%;
    /*
    margin-top:2em;
    */
    margin-left: 1%;
    margin-right: 1%;
    
    margin-bottom:1em;
    height: 10px;

}
.test1{
    border:solid;
    height:750px;
    width:100%;
}


.siz-h{
    font-size:110%;
}

.siz-m{
    font-size: 100%;
}

.siz-s{
    font-size: 90%;
}

#yratio{
   
    float:right;
    font-size: smaller;


}

#currPos{
 
    float:left;

}

#error{
    margin-top:10%;
    width:100%;
    text-align: center;
    color:red;
}

#spinnerwrap{
    margin-top: 20%;
}

#ThroughputDiv{
background-color: antiquewhite;
}


#count{
    width:100%;

    text-align: center;
}

