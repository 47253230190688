
#cagepane{
    position: relative;
    width: 100%;
    height:50rem;

    border: solid;
    border-color: lightgray;
    border-width: thin;
    border-radius: 0.5em;
}


#cage{
    /*
   
    height: 4rem;
    width:4rem;
*/
    border:solid;
    border-width: thin;
    border-radius: 0.5rem;
    background-color: aliceblue;

    box-shadow: 0.1rem  0.1rem 2px gray;
 
}

#PopupWrap{
    background-color: white;
}





#errors p {
    color:red;
    margin: 0em;
    padding-left:0.5em;
    padding-right:0.5em
  }

  .PopEditModal {
 
    width: 90vw !important;
    min-width: 90% !important;  
  }

  .modal-dialog {
    max-width: 45.5em;
  }

  