#TypeSelectsDiv {
    width: 14em;
    padding: 1em; 

}

.TypeAndNumber{

    width: 15em;
    float:left;
    padding-bottom: 1em;
   

   

}

.DivNumber{
    float:left;
    width:3.5em;
    margin-left: 1em;


}

.DivChecked{
    float:left;
    width:1em;
    margin-left: 0.5em;
}

.DivSelect{
    float:left;
    width: 8em;
}

.TheCheck{
    margin-left: 0.5em;
}

#MiscNumbers{
    margin-left: 1em;
    
}

#CheckBoxes{

    display: flex;
    width:100%;
    justify-content:flex-start;
}

#GenButton{
    
    
    
}

#GenResult{
    padding-left: 2em;
    padding-top: 0.5em;
    float:left;
}

#GenWrap {
    width:100%;
}

#PLCcheck {
    margin-left:3em;
}

.shareLink {
    
    
   margin-top: -0.5em;
    width: 100%;
    text-align: center;
    color: #212529;

    
}

.copyLink {
text-indent: 1rem;
}

#DivMsg {
    width: 100%;
    text-align: center;
    font-size: smaller;
    font-style: italic;
    margin-top: 2em;
}

.HeaderText{
    padding-bottom: 0.5em;
    font-weight: bolder;
}

#Comment{
    width: 100%;
    float:left;
    margin-bottom: 2em;
    margin-top:1em;
    margin-left: -1em;
}

#ClearForm{
    text-align: right;
    width:100%;
    float:right;
}

#plcwrap{


    text-align: center;
    
}