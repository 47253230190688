.CageOutlines{
    position: absolute !important;
    border:solid;
    border-width: thin;
    border-color: gray;
    border-radius: 50%;
    box-shadow: 0.4rem  0.4rem 2px rgba(150, 150, 150, .2);
}

.CageOutlines:hover {
    cursor:pointer;
}

.CageName {
    margin-top: 8%;
    width: 100%;
    height:30%;
    text-align: center;

   

}



.PopName {
    
    margin-top: 40%;
    padding-top: 1%;
    width: 100%;
    height:20%;
    text-align: center;
    word-wrap: break-word;
 
    /*font-size: 20px;*/

  /*  background-color: blue;*/

    border:solid;
  
    border-width: thin;
    border-radius: 5px;

    box-shadow: 0.4rem  0.4rem 2px rgba(150, 150, 150, .2);;

    background-image: linear-gradient(to bottom right, silver, white)

}




.CageStatus {
    position: absolute !important;
   top:64%;
    width:115%;
    text-align: right;

}

.Orange{
    color:orange;
}

.Red{
    color: red; 
}
