#SEwrapxx{
    margin: 2em;
    border:solid;
    border-width: thin;
    border-color: lightgray; 
    float:left;
    
    
}

.SEareaInvisible{

    height: 0px;
    overflow: hidden;

    transition: height 3.0s ease-out;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
    

}
.SEarea{
    position: relative;
    border: solid;
    border-width: thin;
    border-color:#007bff;
    border-radius: 0.5em;
    width:100%;
    padding:1em;
    margin-bottom: 1em;
    -moz-transition: height 0.5s ease;
    -webkit-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
    
    
}

#share-text {
    width: 100%;
    text-align: center;
    margin-bottom: 1em;

}

#divSave {
    padding: 2em;
}

.shareLink {
    
    
    padding: 0.5em;
    width: 100%;
    text-align: center;
    color: #212529;

    
}

.copyLink {
text-indent: 1rem;
}

.copyLink:hover{
    cursor: pointer;
}


#SLwrap{
    
   /* padding: 1em;;*/
}

.SLtrash {
    color:red;
    
}

.SLtrash:hover{
    cursor:pointer;
    background-color:gray;
}

