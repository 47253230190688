.liceTable {
    height: 15rem;
    max-height: 20rem;
    overflow-y: scroll ;
    display: inline-block;
    width:auto;
    min-width: 20rem;
    margin: auto;
    vertical-align: middle;
    background-color: rgb(243, 243, 243);
}
.lice_item{
  padding-top:3px;
  padding-bottom:3px;
   
}

.imageWeightFont{
  margin-left:20px;
  font-size: 24px;
}

/* .imageWeight{

} */

fieldset > table > tbody > tr >th{
  border-top: none;
}

.gendertd {
  text-transform: capitalize;
}

.delete_mark_button{
  margin-top:3px;
  margin-bottom: 3px;
  margin-left:3px;
}

.lice-form{
  margin-top: 20px;
  position: relative;
  /* border: 1px solid #ddd;*/
  padding: 10px 10px 10px 10px;
  /* -webkit-box-shadow: 0px 0px 10px 0px rgba(79,78,79,0.65);
  -moz-box-shadow: 0px 0px 10px 0px rgba(79,78,79,0.65);
  box-shadow: 0px 0px 10px 0px rgba(79,78,79,0.65);  */

}

.radioBox{
  width: 15px;
  height: 15px;
  border-radius: 5px;
  position: relative;
  float: right;
  top:5px
}

.red{
  background-color: #c82124;
}

.green{
  background-color: #00cc00;
}

.yellow{
  background-color: #ffff00;
}

.blue{
  background-color: #3333ff;
}

#liceMarkComment{
  width: 100%;
  max-width: 140px;
}

.ImageMarking{
  display: inline-block;
  width: 300px;
}

.ImageMarking .dropdown_list{
  width: 150px;
  margin-left:10px;
  font-family: Roboto!important;
  color: black;
  padding: 4px 0px 7px;
  border: 1px solid #cacccd;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 24px; */
  background-color: #fff;
}

#saveMark {
  position: relative;
  font-size: 14px;
  margin: 15px 0 25px 10px;

}

.error{
  border-color: red;
}
.MarkingList{
  padding-left: 15px;
}