.thresholdcolumn{
    width: 10em;
    float:left;
   
    text-align: center;
    padding: 2px;
}


.TableWrap{
    float: left;
}

#toprow{
    float:left;
    background-color: beige;
}

.wrap{
    width:100%;
}

.dateinput{
float:left;
}

.datelabel{
    text-align: right;
    width:6em;
float:left;
margin-left: 1em;
padding-right: 0.5em;
}


.toprowitemswrap{
    margin-bottom: 1em;
    width: 100%;
}

.popover {
    background-color:blanchedalmond;
}