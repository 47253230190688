
.set-testbox{
    height:60em;
    width:75%;
    background-color: blueviolet;
}

.set-wrap{
    width: 100%;
}

.set-content-wrap{
  
}

.prof-wrap{
    position: relative;
    padding: 1em 1em 1em 1em;
    flex-shrink:initial;
    max-width:40em;
}

.prof-col-fixed{
    position: relative;
    border:solid;
    border-width: thin;
    border-width:1px;
    border-color: gray;
    margin:0.1em;
    width: 1.3em;
    height: 400px;
    background-color:gray;
}

.prof-col-dyno{
    width: 1.2em;
    bottom:0px;
    height: 50px;
}

.bgc-lightgray{
    background-color:lightgray;
}

.bgc-white{
    background-color:white;
}

.prof-columns{
    margin-left: 1em;
    padding: 1em;
    border: solid;
    border-width: thin;
    border-radius: 0.3em;
    border-color: gray;
}

.prof-colitem-wrap{
    position: relative;
    float:left;
    text-align: center;
}

.prof-col-text{
    font-size: smaller;
}

.prof-select-time{
    width:6em;
    margin-right: 2em;
}

.ptc-buttons {
    display: inline-block;
    margin:1em 0em 1em;
    width: 20em;
 

}

.sgr-wrap{
    
}

.sgr-fields-wrap{
    float:left;
    position: relative;
    padding: 1em 1em 1em 0em;
    margin-right: 3em;

    width:30em;

}

.sgr-table-wrap{
    overflow: auto;
}

.sgr-column {
    background-color: brown;
    width: 3em;
}


table thead .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    }
    
    table thead .dropup .caret, table thead .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
    }


.penc-fields{
    
    
    display: inline-block;
    padding: 1em 1em 1em 1em;    margin-right: 3em;
    margin: 1em 1em 1em;
    width:20em;
    border:solid;
    border-width: thin;
    border-radius: 0.3em;
    border-color: silver;
 


} 

.penc-buttons{
   
    float:left;
    margin:1em 1em 1em;
    padding:1em;
    width: 20em;
    border:solid;
    border-width: thin;
    border-radius: 0.3em;
    border-color: silver;
 
}

.penc-texts {
    color:darkslategray;
    font-size: smaller;

}





.penc-wrap .form-control {
    width: 6em !important;
  }


  .penc-fields-wrap{
      display:flex;
      flex-wrap: wrap;
      justify-content:flex-start;
    width:100%;
   
   
 

  }


  .hid-wrapitems{
      margin-top:1em;
      max-height: 40em;
     
  }

  .hid-item{
      display: inline-block;
      width:10em;

  }

  .hid-label{
      float:right;
      width:6em;
      display:block;
      padding-left: 0.5em;
      text-align: left;
  }

  #NewCagesTableWrap {
    margin:1rem;
    width: 12rem;
    
  }

  .NewCagesNextButton{
    height: 2.5rem !important;
  }

  .errormsg{
    color:red;
  }