.dl-buttons > a {
    /*float: left;*/
    /*margin-right:10px;*/
    background-color: #337ab7;
    color: white;
    padding: 5px 10px 5px 5px;
    border-radius: 5px;
    
}
.dl-buttons > h3 {
    text-align: left;
}
.dl-buttons{
    margin-top: 15px;
    /*float: left;*/
}

#MSE-dl{
    z-index: 1500;
}