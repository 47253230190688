.licence-container{
    height: auto;
}
.licence-generator-container{
    width: 800px;
    margin: 0px auto;
}
.counters-checkboxes{
    width: 225px;
    background-color: lightgray;
    margin: 10px 10px;
    padding-left: 10px;
}
.form-container{
    padding-left: 5px;
}
.form-container input{
    margin-right: 5px;
}
.form-group{
    padding-left: 5px;
}
.form-group input{
    width: auto;
}
.form-group label{
    width: 200px;
}
.products-checkboxes{
    width: 225px;
    background-color: lightgray;
    margin: 10px 10px;
    padding-left: 10px;
}
.timebox{
    margin: 10px 10px;
    padding-left: 15px;
}
.calculate-box{
    margin: 10px 10px;
    padding-left: 250px;
}
@media (max-width: 767px) {
    .calculate-box{
        margin: 10px 10px;
        padding-left: 0px;
    }
}
.inputs-conatiner{
    width: 198px;
    margin: 10px 10px;
    padding-left: 10px;
}