.loading{
  position: fixed;
  top: 60%;
  left: 50%;
}
/* .loading-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, .3);
}

.loading-icon {
  position: absolute;
  font-size: 20px;
  top: calc(50%);
  left: calc(49.5%);
  margin-left: -50px;
  margin-right: -50px;

  z-index: 10;
  animation: spin .7s infinite linear;
  -animation: spin .7s infinite linear;
  -webkit-animation: spin2 .7s infinite linear;
  z-index: 100;
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
} */