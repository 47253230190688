.copyRight {

    font-size: smaller;
    color:gray;
    display: inline-block;
    float:right;
}

.accessibility-link {
   float:left;
    color:gray !important;
    margin-left: 1em;
}

.accessibility-link:hover{
  background-color: darkgrey !important;
}

.accessibility-img  {
    float:left;
    height: 2.6em;

}

.wrapper {

    width:100%;
    margin-bottom: 1em;
    padding:0em 2em 0em 2em;
    clear:both;
  

}




button {
    appearance: auto;
    margin: 0em;
    padding: 1px 6px;
    border:none;
    background-color: white;
    color:gray;
    /*
    border-width: 1px;
    border-style: outset;
    border-color: gray;
    border-image: initial;
    */


}


.cookie-button-div {
    margin-left: 2em;

    float:left;
}

button:hover{
    background-color: darkgrey !important;
  }