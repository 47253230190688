/* Add a black background color to the top navigation */
.top-nav {
  background-color: rgb(251, 251, 251);
  overflow: hidden;
  height: 48px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.479);
}
/* logo setup*/
.top-nav #logo {
  float: left;
  text-align: center;
  padding: 14px 0px;
  text-decoration: none;
}
/*Logo Styling*/
#logo {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  letter-spacing: -3px;
  color: #c09f1a;
  padding-left: 20px;
  padding-right: 40px;
  margin-right: 10px;
  margin-top: -10px;
}
/* Navigation links Home AdminView etc*/
#right-side {
  float: right;
  /*margin-right: 25px;*/
}
.top-nav a {
  float: left;
  color: #818181;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
}
.sign-out-button {
  height: 32px;
  font-size: 16px;
  margin-top: 8px;
  background-color: #c09f1a;
  color: white;
  border: none;
  padding: 0px 15px 0px 15px;
}
#greeting {
  padding-right: 15px;
  font-size: 16px;
  color:black;
}
.top-nav a.activenav {
  background-color: #ddd;
}
.top-nav a:hover {
  background-color: #ddd;
}