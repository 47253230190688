#SCwrap{
    margin: 2em;
    border:solid;
    border-width: thin;
    border-color: lightgray;

}

#logo {
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    letter-spacing: -3px;
    color: #c09f1a;
    padding-left: 20px;
    padding-right: 40px;
    margin-right: 10px;
    margin-top: -10px;
  }

  #header{
    width:100%;
    text-align: center;
  }
  #subtitle{
    color:gray;
    max-width: 20em;
  }