.act-wrap{
    width: 100%;
}

.act-content-wrap{
  
}


.mf-wrap {
    position: relative;
    min-height: 30em;
    width: 60em;
     padding: 1em 4em 1em 4em;
}

.mf-close {
    text-align: right;
    width: 100%;
    margin-top:4em;
     

}

.mf-errortext{
    display: inline-block;
    width: 100%;
}

