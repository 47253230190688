#LayoutPane{
    position: relative;
    border:solid;
    border-width: 2px;
    border-color: #96dbfa; /*  #007bff;*/
    border-radius: 0.5em;
    background-image: linear-gradient(to bottom right, #f8f9fa , white );
}
/*    #D6EAF8  */
.LayoutCage{
    position: absolute;
    border:solid;
    border-width: thin;
    border-radius: 3px;

}
