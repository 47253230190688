.inl {
    display: block;
    font-size: 1.2rem!important;  
}
.inl-front {
    display: inline-block;
    width: 100%;
    text-align: left;
    color: black;
}
.inl-back {
    display: inline-block;
    width: auto;
    text-align: left;
    color: #686868;
}